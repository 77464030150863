














































import { Component, Vue, Prop } from 'vue-property-decorator';
import ExcluirComprovante from './ExcluirComprovante.vue';

@Component({
  components: {
    ExcluirComprovante,
  },
})
export default class ComprovantesItem extends Vue {
  @Prop() public readonly item!: IComprovante;
  @Prop({ default: false }) public readonly deleteBtn!: boolean;
  public prefixoUrl = 'https://arquivos.parceirosapvstruck.partnerson.com.br';
  public processing = false;

  get url(): string {
    return `${this.prefixoUrl}${this.item.comprovante}`;
  }

  get nome(): string {
    return this.item.nome !== '' ? this.item.nome : 'Comprovante';
  }

  public change(): void {
    this.$emit('change');
  }
}
