





















import { Component, Vue, PropSync, Prop } from 'vue-property-decorator';
import ComprovantesItem from './ComprovantesItem.vue';

@Component({
  components: {
    ComprovantesItem,
  },
})
export default class ComprovantesGrid extends Vue {
  @Prop({ default: false }) public readonly deleteBtn!: boolean;
  @PropSync('items', { default: [] })
  public readonly comprovantes!: IComprovante[];

  public get empty(): boolean {
    return (this.comprovantes ?? []).length === 0;
  }

  public change(): void {
    this.$emit('change');
  }
}
