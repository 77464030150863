













































































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Indicador } from '@/plugins/api';

@Component
export default class EditarBanco extends Vue {
  @Prop() public readonly dados!: IDadosBancarios;
  public editar = {} as IDadosBancarios;
  public processing = false;

  public contaTipos = [
    { id: 'cc', label: 'Corrente' },
    { id: 'cp', label: 'Poupança' },
  ];

  public pixTipos = [
    { id: 'nao_usar', label: 'Não Usar' },
    { id: 'cpf_cnpj', label: 'CPF/CNPJ' },
    { id: 'telefone', label: 'Telefone' },
    { id: 'email', label: 'E-mail' },
    { id: 'chave_aleatoria', label: 'Chave Aleatória' },
  ];

  mounted(): void {
    this.editar = Object.assign({}, this.dados);
  }

  @Watch('dados')
  dadosChanged(): void {
    this.editar = Object.assign({}, this.dados);
  }

  get bancos(): IBanco[] {
    return this.$store.state.bancos ?? [];
  }

  public salvar(): void {
    this.processing = true;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let dados = ({ ...this.editar } as unknown) as any;
    if (dados.bancos) {
      dados.banco = dados.bancos.id;
    }
    delete dados.id;
    delete dados.usuarios;
    delete dados.bancos;
    dados = dados as IDadosBancarios;

    Indicador.updateBanco(this.dados.id_indicador, dados)
      .then((response: GenericResponse) => {
        this.$emit('change', response.success);
        this.dismiss();
      })
      .finally(() => {
        this.processing = false;
      });
  }

  dismiss(): void {
    this.$root.$emit('bv::hide::popover');
  }
}
